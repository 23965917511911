const RECRUITEE_BACKEND = {
  protocol: 'https',
  referralsProtocol: 'https',
  domain: 'api.recruitee.com',
  partialDomain: 'recruitee.com',
  referralsPartialDomain: 'referrals.co',
  apiUrl: 'https://api.recruitee.com',
  authUrl: 'https://auth.recruitee.com',
  appUrl: 'https://recruitee.com',
  webAppUrl: 'https://app.recruitee.com',
  mobileAppUrl: 'https://mobile.recruitee.com',
  wsUrl: 'wss://api.recruitee.com/socket',
  widgetUrl: 'https://jobs-widget.recruiteecdn.com/widget.js',
  widgetApiEndpoint: 'https://career.recruitee.com/api/c',
  videoAppUrl: 'https://meet.recruitee.com',
  CDNHost: 'https://app.recruitee.com',
  pdfViewerDirectory: 'pdfjs-patched',
  integrationsUrl: 'https://integrations.recruitee.com',
  tellentApiUrl: 'https://api.tellent.com',
  tellentAuthUrl: 'https://auth.tellent.com',
  tellentAppUrl: 'https://admin.tellent.com'
};

/**
 * Generated bundle index. Do not edit.
 */

export { RECRUITEE_BACKEND };
